import ContactOptions from "../components/NewContactUs/ContactOptions/ContactOptions";
import SendMessage from "../components/NewContactUs/SendMessage/SendMessage";

export default function Contact(){
    return(
        <>
        <ContactOptions/>
        <SendMessage />
        </>
    )
}